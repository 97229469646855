<template>
	<div class="test">
		<div class="page_1">
			<PageNav :show_num="4" />
			<div class="page_1_center_box">
				<div class="cen-text">
					COMPANY PROFILE
				</div>
				<div class="cen-line">

				</div>
			</div>
		</div>
		<div class="page_2">
			<div class="page_2_1">
				<div class="page_2_1_bot">
					<div class="page_2_1_bot_left">
						<div class="top-mess">
							<span style="color: #FF720A;">INNOVATION</span> LEADS
							TO SMARTER FUTURE
						</div>
						<div class="bot-mess">
							SZZT is a professional fintech solution provider started from 1993, focusing on retail and
							banking industry, providing comprehensive solutions covering payment terminals, self-service
							terminals and platform services, helping to build an efficient and convenient financial
							service network, and leading to a smarter future.
						</div>
					</div>
					<div class="page_2_1_bot_right">
						<img class="big-img" :src="acctive_img" alt="" />
						<div class="add-class">
							<div class="add-left">
								{{ acctive_add.name }}
							</div>
							<div class="add-right">
								<img class="add-right-img" src="../../assets/images/about/address.png" alt="" />
								{{ acctive_add.address }}
							</div>
						</div>
						<div class="img-list">
							<div :style="item.acctive ? 'opacity:0.6; filter: alpha(opacity=60);' : ''"
								v-for="(item,index) in data_list" :key="index">
								<img class="add-img-item" @mouseenter="enter_img(item)"  :src="item.img" alt="" />
							</div>
						</div>
					</div>
				</div>
				<div class="page_4_box_2">
					<div class="page_4_box_2_item">
						<div class="item-num">
							<img src="../../assets/images/1993.png" alt="" />
						</div>
						<div class="item-con">
							Founded in
						</div>
					</div>
					<div style="width: 1px;height: 50px;background-color: #C8C8C8;">

					</div>
					<div class="page_4_box_2_item">
						<div class="item-num">
							<img src="../../assets/images/6bill.png" alt="" />
						</div>
						<div class="item-con">
							Total assets
						</div>
					</div>
					<div style="width: 1px;height: 50px;background-color: #C8C8C8;">

					</div>
					<div class="page_4_box_2_item">
						<div class="item-num">
							<img src="../../assets/images/10m.png" alt="" />
						</div>
						<div class="item-con">
							Total shipments
						</div>
					</div>
					<div style="width: 1px;height: 50px;background-color: #C8C8C8;">

					</div>
					<div class="page_4_box_2_item" style="border: 0;">
						<div class="item-num">
							<img src="../../assets/images/160+.png" alt="" />
						</div>
						<div class="item-con">
							Countries
						</div>
					</div>
				</div>

			</div>
		</div>
		<div class="page_3">
			<div class="page_3_map">

			</div>
		</div>
		<div class="page_4">
			<div class="page_4_1">
				<div class="page_4_1_top">
					<div class="top-title">
						HIGHLEVEL SCIENTIFIC RESEARCH ABILITY
					</div>
					<div class="bot-line">

					</div>
				</div>
				<div class="page_4_1_box">
					<div class="page_4-item">
						<div class="top-img">
							<img class="top-img" src="../../assets/images/about/news_1.png" alt="" />
						</div>
						<div class="box-num">
							800+
						</div>
						<div class="line-class">
							<div class="succ-line" style="width: 80%;" >

							</div>
						</div>
						<div class="box-text">
							A team of high-quality enneersand researchers,over 70% of them have years R&D
						</div>
					</div>
					<div class="page_4-item">
						<div class="top-img">
							<img class="top-img" src="../../assets/images/about/news_2.png" alt="" />
						</div>
						<div class="box-num">
							300+
						</div>
						<div class="line-class">
							<div class="succ-line" style="width: 30%;">

							</div>
						</div>
						<div class="box-text">
							Accumulated 309 patentapplications, including 102invention patents
						</div>
					</div>
					<div class="page_4-item">
						<div class="top-img">
							<img class="top-img" src="../../assets/images/about/news_3.png" alt="" />
						</div>
						<div class="box-num">
							100+
						</div>
						<div class="line-class">
							<div class="succ-line" style="width: 10%;">

							</div>
						</div>
						<div class="box-text">
							Obtained a number of indus-try qualifications and productcertifications such as CMMI / ISP /
							VISA / PCI / CE / R&D
						</div>
					</div>
				</div>




			</div>
		</div>
		<div class="page_5">
			<div class="page_5_1">
				<div class="page_5_1_top">
					<div class="top-title">
						CUSTOMIZATION CAPABILITY FOR FINANCIAL ELECTRONIC TERMINAL
					</div>
					<div class="bot-line">

					</div>
					<div class="bot-text">
						Business coverage 5 continents, 160 countries and regions around the world.
					</div>
				</div>
				<div class="page_5_1_bot">
					<div class="page_5_1_bot_left">
						<div class="page_5_img">

						</div>
						<div class="page_5_box">
							Powerful R&D Capability
						</div>
					</div>
					<div class="page_5_1_bot_right">
						<div class="acc-list">
							<div class="acc-item">
								<img class="acc-item" src="../../assets/images/about/perfect_1.png" alt="" />
							</div>
							<div class="acc-item">
								<img class="acc-item" src="../../assets/images/about/perfect_2.png" alt="" />
							</div>
							<div class="acc-item">
								<img class="acc-item" src="../../assets/images/about/perfect_3.png" alt="" />
							</div>
						</div>
						<div class="acc-list">
							<div class="acc-item">
								<img class="acc-item" src="../../assets/images/about/perfect_4.png" alt="" />
							</div>
							<div class="acc-item">
								<img class="acc-item" src="../../assets/images/about/perfect_5.png" alt="" />
							</div>
							<div class="acc-item">
								<img class="acc-item" src="../../assets/images/about/perfect_6.png" alt="" />
							</div>
						</div>
						<div class="acc-text">
							Perfect Service
						</div>
					</div>
				</div>


			</div>
		</div>
		<div class="page_6">
			<div class="page_6_1_top">
				<div class="top-title">
					PROFESSIONAL QUALIFICATION CERTIFICAION
				</div>
				<div class="bot-line">

				</div>
			</div>
		</div>

		<PageBot :show_num="4" />
		<el-backtop class="back_up" target="" :bottom="100" :visibility-height="620" :right="10">
			<svg class="icon icon_xiangshang" aria-hidden="true">
				<use xlink:href="#icon-xiangshang"></use>
			</svg>
		</el-backtop>
	</div>
</template>

<script>
	import PageNav from "@/components/PageNav.vue"
	import PageBot from "@/components/PageBottom.vue"
	import Swiper from "swiper";
	import "swiper/css/swiper.min.css";
	import {
		resetRem
	} from '@/all_js/all';
	import {
		clickEffect
	} from "@/all_js/click";
	export default {
		name: 'about',
		components: {
			PageBot,
			PageNav
		},
		created() {
			// resetRem(); // 根据1920尺寸设置rem，1rem=100px
			// clickEffect(); // 引入鼠标点击特效
		},
		watch: {
		},

		mounted() {

		},
		data() {
			return {
				avtive_nav: "",
				show_device_detail: false,
				device_detail: {},
				currentSwiper: null,
				currentSwipers: null,
				acctive_img: require("../../assets/images/about/box_1.png"),
				acctive_add:{
						id: 0,
						img: require("../../assets/images/about/box_1.png"),
						address:"Shenzhen",
						name:"SZZT Industrial Park",
						acctive: true
					},
				show_top: false,
				data_list: [{
						id: 0,
						img: require("../../assets/images/about/box_1.png"),
						address:"Shenzhen",
						name:"SZZT Industrial Park",
						acctive: true
					}, {
						id: 1,
						img: require("../../assets/images/about/box_2.png"),
						address:"Changsha",
						name:"SZZT Cloud Valley Science Park",
						acctive: false
					},
					{
						id: 2,
						img: require("../../assets/images/about/box_3.png"),
						address:"Dongguan",
						name:"Hongda Telecom Industrial Park",
						acctive: false
					},
					{
						id: 3,
						img: require("../../assets/images/about/box_4.png"),
						address:"Xi'an",
						name:"Shanxi Big Data Industrial Park",
						acctive: false
					},

				],
				flag_status: true,
				navBarFixed: false,
				screenWidth: null,
				list_status: true,
				avtive_img: 0
			}
		},
		methods: {
			// 鼠标移入-导航
			enter_img(item) {
				this.acctive_img = item.img;
				this.acctive_add = item;
				for (var i = 0; i < this.data_list.length; i++) {
					if (item.id == this.data_list[i].id) {
						this.data_list[i].acctive = true;
					} else {
						this.data_list[i].acctive = false;
					}
				}
			},
			// 滚动吸顶
			watchScroll() {
				// 当滚动超过 90 时，实现吸顶效果
				var scrollTop =
					window.pageYOffset ||
					document.documentElement.scrollTop ||
					document.body.scrollTop;
				if (scrollTop > 90) {
					this.navBarFixed = true;
				} else {
					this.navBarFixed = false;
				}
			},
			go_index() {
				window.location.href = "/";
			},
			go_contact() {
				this.$router.push({
					path: '/contact',
					query: {
						id: '123456'
					},

				})
			},
		}
	}
</script>
<style scoped lang="less">
	* {
		margin: 0;
		padding: 0;
		// transition: all 1s; // 设置动画效果
	}

	li {
		list-style-type: none;
	}

	.page_1 {
		width: 100%;
		height: 600px;
		background-image: url("../../assets/images/about/top_bg.png");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none; // 子元素无法被选中
	}


	.page_1_center_box {
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		text-align: center;
		color: #FFFFFF;

		.cen-text {
			margin-top: 230px;
			font-size: 60px;
		}

		.cen-line {
			width: 127px;
			height: 4px;
			margin: 0 auto;
			margin-top: 30px;
			background-color: #FFFFFF;
		}


	}








	.back_up {
		height: 40px;
		width: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 30px;
		background-color: #49b1f5;

		.icon_xiangshang {
			height: 30px;
			width: 30px;
			border-radius: 30px;
		}
	}

	.back_up:hover {
		background-color: #49b1f5;
	}
</style>
<style scoped lang="less">
	// @media only screen and (min-width:1371px) {}
	
		.page_2 {
			width: 100%;
			min-width: 1360px;
			background: linear-gradient(180deg, #FCFDFD 0%, #F7F9F9 100%);
		}
	
		.page_2_1 {
			width: 1360px;
			margin: 0 auto;
			padding-bottom: 80px;
	
	
			.page_2_1_top {
				margin-top: 100px;
				width: 100%;
				text-align: center;
				display: flex;
				flex-direction: column;
				align-items: center;
	
				.top-title {
					color: #000000;
					font-size: 48px;
				}
	
				.bot-line {
					margin-top: 20px;
					width: 127px;
					height: 4px;
					background-color: #0081CC;
				}
	
			}
	
			.page_2_1_bot {
				width: 100%;
				margin-top: 80px;
				display: flex;
				justify-content: space-between;
	
				.page_2_1_bot_left {
					width: 540px;
					height: 100%;
	
					.top-mess {
						margin-top: 120px;
						width: 380px;
						text-align: left;
						color: #252525;
						font-size: 36px;
					}
	
					.bot-mess {
						margin-top: 30px;
						text-align: left;
						color: #5A5A5A;
						font-size: 16px;
					}
	
				}
	
				.page_2_1_bot_right {
					width: 720px;
					height: 100%;
					display: flex;
					flex-direction: column;
	
					.big-img {
						width: 100%;
						height: 400px;
					}
	
					.add-class {
						width: 100%;
						height: 73px;
						margin-top: -73px;
						background: linear-gradient(316deg, #00B7E8 0%, #0081CC 100%);
						display: flex;
						align-items: center;
						justify-content: space-between;
						color: #FFFFFF;
	
						.add-left {
							margin-left: 25px;
							font-size: 24px;
						}
	
						.add-right {
							cursor: pointer;
							margin-right: 25px;
							font-size: 16px;
							display: flex;
	
							.add-right-img {
								margin-right: 10px;
							}
						}
					}
	
					.img-list {
						width: 100%;
						height: 100px;
						display: flex;
						justify-content: space-between;
						margin-top: 10px;
	
	
						.add-img-item {
							cursor: pointer;
							width: 170px;
							height: 100%;
						}
					}
	
	
				}
	
	
			}
	
			.page_4_box_2 {
				width: 100%;
				height: 165px;
				margin-top: 60px;
				display: flex;
				align-items: center;
				background: #FFFFFF;
				box-shadow: 0px 5px 30px 0px rgba(230, 230, 230, 0.5);
	
				.page_4_box_2_item {
					width: 25%;
					height: 100%;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					// border-right: 1px solid #C8C8C8;
	
					.item-num {
						height: 70px;
	
						img {
							height: 70px;
							object-fit: cover; // 保持图片缩放时候不变形
						}
					}
	
					.item-con {
						font-size: 21px;
						color: #252525;
					}
	
				}
	
	
			}
		}
	
		.page_3 {
			width: 100%;
			min-width: 1360px;
			background: #FFFFFF;
	
			.page_3_map {
				width: 1378px;
				margin: 0 auto;
				height: 876px;
				margin-top: 40px;
				background-image: url("../../assets/images/map.png");
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center center;
	
	
	
			}
		}
	
	
	
		.page_4 {
			width: 100%;
			height: 968px;
			min-width: 1360px;
			background-image: url("../../assets/images/about/page4_bg.png");
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;
		}
	
		.page_4_1 {
			width: 1360px;
			margin: 0 auto;
			padding-bottom: 80px;
	
			.page_4_1_top {
				width: 100%;
				text-align: center;
				display: flex;
				flex-direction: column;
				align-items: center;
	
				.top-title {
					margin-top: 120px;
					color: #ffffff;
					font-size: 48px;
				}
	
				.bot-line {
					margin-top: 20px;
					width: 127px;
					height: 4px;
					background-color: #ffffff;
				}
			}
	
			.page_4_1_box {
				width: 100%;
				height: 529px;
				margin-top: 80px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				border-radius: 25px;
				// opacity: 0.15;
				// background: #D8D8D8;
				background: rgba(216, 216, 216, 0.15);
				// border: 1px solid #979797;
	
	
				.page_4-item {
					width: 410px;
					height: 460px;
					display: flex;
					flex-direction: column;
					color: #FFFFFF;
	
					.top-img {
						width: 410px;
						height: 248px;
					}
	
					.box-num {
						font-size: 57px;
						margin-top: 30px;
					}
	
					.line-class {
						width: 100%;
						height: 3px;
						background-color: #FFFFFF;
	
						.succ-line {
							width: 60%;
							height: 3px;
							background-color: #3FFFE2;
						}
					}
	
					.box-text {
						width: 100%;
						font-size: 16px;
						margin-top: 10px;
						text-align: left;
					}
	
	
	
				}
	
				.page_4-item :first-child {
					margin-left: 0;
				}
	
				.page_4-item :last-child {
					margin-right: 0;
				}
	
	
			}
	
			.page_4_1_box :first-child {
				margin-left: 30px;
			}
	
			.page_4_1_box :last-child {
				margin-right: 30px;
			}
	
	
	
	
	
	
		}
	
		.page_5 {
			width: 100%;
			min-width: 1360px;
			background: #FFFFFF;
		}
	
		.page_5_1 {
			width: 1360px;
			margin: 0 auto;
	
	
			.page_5_1_top {
				margin-top: 100px;
				width: 100%;
				text-align: center;
				display: flex;
				flex-direction: column;
				align-items: center;
	
				.top-title {
					color: #000000;
					font-size: 48px;
				}
	
				.bot-line {
					margin-top: 20px;
					width: 127px;
					height: 4px;
					background-color: #0081CC;
				}
	
				.bot-text {
					width: 100%;
					margin-top: 20px;
					color: #979797;
					font-size: 21px;
					text-align: center;
				}
			}
	
			.page_5_1_bot {
				width: 100%;
				height: 530px;
				margin-top: 80px;
				display: flex;
				justify-content: space-around;
	
	
				.page_5_1_bot_left {
					width: 430px;
					height: 100%;
	
					.page_5_img {
						width: 430px;
						height: 430px;
						background-image: url("../../assets/images/about/full.png");
					}
	
					.page_5_box {
						width: 100%;
						text-align: center;
						font-size: 24px;
						margin-top: 50px;
						color: #000000;
	
					}
	
				}
	
				.page_5_1_bot_right {
					width: 640px;
					height: 100%;
	
					.acc-list {
						width: 100%;
						margin-bottom: 18px;
						display: flex;
						justify-content: space-between;
	
						.acc-item {
							border-radius: 15px;
							overflow: hidden;
							width: 200px;
							height: 200px;
						}
					}
	
					.acc-text {
						width: 100%;
						margin-top: 64px;
						text-align: center;
						font-size: 24px;
						color: #000000;
					}
	
	
				}
			}
	
	
		}
	
		.page_6 {
			margin-top: 80px;
			width: 100%;
			height: 960px;
			background-image: url("../../assets/images/about/page6_bg.png");
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;
	
			.page_6_1_top {
				width: 100%;
				text-align: center;
				display: flex;
				flex-direction: column;
				align-items: center;
	
				.top-title {
					margin-top: 80px;
					color: #ffffff;
					font-size: 48px;
				}
	
				.bot-line {
					margin-top: 20px;
					width: 127px;
					height: 4px;
					background-color: #ffffff;
				}
			}
	
		}
</style>